<template>
    <v-card class="mt-3">
        <v-card-title>Итого</v-card-title>
        <v-card-text>
            <div>{{date.start}}</div>
            Аренда зала "{{room.name}}": {{totalPrice}} &#8381; ({{date.duration}} {{pluralizedHours}} аренды
            <template v-if="endOnDay"> + 1 час на уборку</template>
            )
            <br>
            Уборка: {{totalCleaning}} &#8381;
            <div v-html="additionalFeaturesCalc"></div>
            <v-divider></v-divider>
            Итого: {{totalPrice + additionalPrice + totalCleaning}} &#8381; {{pledge}}
            <template v-if="alco && guests.security"><br>Дополнительно оплачиваются услуги охраны на мероприятии
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
    import moment from "moment";


    export default {
        name: "TotalPriceCard",
        props: ['guests', 'room', 'hours', 'alco', 'date', 'additionalFeatures'],
        computed: {
            endOnDay() {
                const time = this.time.clone().add(this.date.duration, 'hour');
                return time.hour() < 22 && time.hour() >= 9;
            },
            pluralizedHours() {
                const forms = ['час', 'часа', 'часов'];
                let n = this.date.duration;
                return n % 10 == 1 && n % 100 != 11
                    ? forms[0]
                    : (n % 10 >= 2 && n % 10 <= 4
                    && (n % 100 < 10
                        || n % 100 >= 20) ? forms[1] : forms[2]);
            },
            totalCleaning() {
                return this.room.cleaning + (this.additionalFeatures.garbage ? 500 : 0);
            },
            totalPrice() {
                let price = 0;
                const duration = this.endOnDay ? this.date.duration + 1 : this.date.duration;
                for (let i = 0; i < duration; i++) {
                    const time = this.time.clone().add(i, 'hour');
                    const weekday = this.isWeekDay(time);
                    if (weekday) {
                        let prices = this.room.price.weekday;
                        if (time.hour() >= 0 && time.hour() < 9) {
                            price += (prices.evening + this.guests.price)
                        } else {
                            price += (prices.day + this.guests.price)
                        }
                    } else {
                        let prices = this.room.price.weekend;
                        if (time.hour() >= 0 && time.hour() < 9) {
                            price += (prices.evening + this.guests.price)
                        } else {
                            price += (prices.day + this.guests.price)
                        }
                    }
                }
                return price;
            },
            additionalPrice() {
                let featurePrice = 0;
                if (this.additionalFeatures.projector) {
                    featurePrice += this.date.duration <= 4 ? 800 : 1600;
                }
                if (this.additionalFeatures.godox) {
                    featurePrice += this.date.duration < 4 ? this.date.duration * 300 : 1000;
                }
                if (this.additionalFeatures.dishes20) {
                    featurePrice += 500;
                }
                if (this.additionalFeatures.dishes40) {
                    featurePrice += 1000;
                }
                if (this.additionalFeatures.glasses20) {
                    featurePrice += 500;
                }
                if (this.additionalFeatures.glasses40) {
                    featurePrice += 1000;
                }
                if (this.additionalFeatures.impulse) {
                    featurePrice += this.date.duration < 4 ? this.date.duration * 300 : 1000;
                }
                return featurePrice
            },
            pledge() {
                return this.alco ? `+ залог ${this.room.pledge.base} ₽ + залог за наличие алкоголя ${this.room.pledge.alco} ₽` : `+ залог ${this.room.pledge.base} ₽`;
            },
            time() {
                return moment(this.date.start, "DD-MM-YYYY HH:mm");
            },
            additionalFeaturesCalc() {
                let featureString = "";
                if (this.additionalFeatures.projector) {
                    featureString += `<div>Проектор Benq TH671ST: ${this.date.duration <= 4 ? "800" : "1600"} ₽</div>`
                }
                if (this.additionalFeatures.godox) {
                    featureString += `<div>Видеосвет Godox sl200ii: ${this.date.duration < 4 ? this.date.duration * 300 : "1000"} ₽</div>`
                }
                if (this.additionalFeatures.impulse) {
                    featureString += `<div>Импульсный свет Profoto: ${this.date.duration < 4 ? this.date.duration * 300 : "1000"} ₽</div>`
                }
                if (this.additionalFeatures.dishes20) {
                    featureString += `<div>Тарелки и приборы 20 шт.: 500 ₽</div>`
                }
                if (this.additionalFeatures.dishes40) {
                    featureString += `<div>Тарелки и приборы 40 шт.: 1000 ₽</div>`
                }
                if (this.additionalFeatures.glasses20) {
                   featureString += `<div>Стаканы 20 шт.: 500 ₽</div>`
                }
                if (this.additionalFeatures.glasses40) {
                   featureString += `<div>Стаканы 40 шт.: 1000 ₽</div>`
                }
                if (this.additionalFeatures.chairs) {
                    featureString += `<div>Раскладные стулья: 0 ₽</div>`
                }
                if (this.additionalFeatures.tables) {
                  featureString += `<div>Раскладные столы: 0 ₽</div>`
                }
                if (this.additionalFeatures.sony) {
                    featureString += `<div>Колонка Sony GTK-XB72: 0 ₽</div>`
                }
                if (this.additionalFeatures.flipchart) {
                    featureString += `<div>Флипчарт: 0 ₽</div>`
                }
                if (this.additionalFeatures.garbage) {
                    featureString += `<div>Сыпучий реквизит: +500 ₽ к стоимости уборки</div>`
                }
                return featureString;
            }
        },
        methods: {
            isWeekDay(day) {
                return day.day() > 0 && day.day() < 5;
            }
        },
        updated() {
            this.$emit('update', {
                total: this.totalPrice + this.additionalPrice + this.totalCleaning,
                cleaning: this.totalCleaning,
                pledge: this.pledge
            });
        }
    }
</script>

<style scoped>

</style>